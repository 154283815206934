<template>
  <div v-if="isDataLoaded">
    <div class="mr-auto pl-5 py-4">
      <div class="d-flex flex-row align-center pl-16">
        <v-icon
          color="primary"
          size="16"
        >
          arrow_back
        </v-icon>
        <a @click="$router.push({ name: 'calendar-schedule' })">
          <span class="ml-1 font-weight-bold">{{ $t('activity.editActivity.navigateBack') }}</span><span>{{ $t('project.sections.calendar') }}</span>
        </a>
      </div>
    </div>
    <v-tabs v-model="tab" color="secondary">
      <v-tabs-slider color="primary" />
      <v-tab v-for="{ id, name, to } in tabs" :key="id" class="text-capitalize" :to="to" v-text="name" />
    </v-tabs>
    <v-divider />
    <router-view />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'AcademyEvent',
  props: {
    eventId: { type: String, default: null },
  },
  data() {
    return {
      isDataLoaded: false,
      tab: null,
    }
  },
  computed: {
    ...mapGetters({ activity: 'activity/data' }),
    tabs() {
      return [
        {
          id: 'applications',
          name: this.$t('project.academy.calendar.applications'),
          to: { name: 'event-edit' },
        },
        {
          id: 'attendance',
          name: this.$t('project.academy.calendar.attendance'),
          to: { name: 'attendance-event' },
        },
      ]
    },
  },
  async created() {
    this.$store.commit('loader/show')
    const { organizationId, projectId, eventId: activityId } = this.$route.params
    if (activityId) {
      const entity = await this.$store.dispatch('activity/bind', { organizationId, projectId, activityId })
      if (!entity) this.$router.replace({ name: '404' })
    }
    this.$store.commit('loader/hide')
    this.isDataLoaded = true
  },
}
</script>
